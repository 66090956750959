// React
import React from 'react';
import cloneDeep from 'lodash/cloneDeep';

// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from '../../../store/actions/actions';

// Material
import { Button } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

// Class
import Basics from '../basics/basics';
import Preferences from '../preferences/preferences';
import Accounts from '../accounts/accounts';
import Data from '../data/data';
import { FormattedMessage } from "react-intl";
import { injectIntl } from 'react-intl';

// Utils

// Service
import ProfileService, { UserProfileAPI } from '../../../services/profileService';
import IdentityService, { IdentityAPI } from '../../../services/identityService';

class UserForm extends React.Component {

     /**
	 * @function constructor
	 * @param {*} props - Having properties passed by parent element
	 *  * used for state initialization
	 */
     constructor(props) {
          super(props);
          this.state = {
               user: null,
               changeStatus: 0,
               modified: false,
               error: {},
               message: "",
               onChanges: this.onChanges.bind(this)
          };
     }

     /**
     * @function componentDidMount
     * @description called when the component in first time mounted.
     *  this will be called before parent mounting and after child mounting
     */
     componentDidMount() {
          if (!this.props.user?.firstName) {
               this.loadMyDetails();
               this.loadIdentityDetails();
          }


     }

     onChanges() {
          this.reload(true);
     }


     /**
     * @function render
     * @description called when the component changes its state, props value
     */
     render() {
          let isUpdated = this.verifyUpdates();

          return (
               <form className="user-form">
                    <div className="actions">
                         <Button
                              type="button"
                              variant="contained"
                              size="large"
                              disableElevation
                              className="buttonLink"
                              onClick={() => { this.reload(false) }}
                              disabled={!isUpdated}
                         >
                              <FormattedMessage defaultMessage="Cancel" />

                         </Button>
                         <Button
                              type="button"
                              variant="contained"
                              color="primary"
                              size="large"
                              disableElevation
                              onClick={this.saveUserDetails}
                              disabled={!isUpdated}
                         >
                              <FormattedMessage defaultMessage="Save" />

                         </Button>
                    </div>
                    <div className="form-sections" key="form-sections">
                         <div className="row-sections">
                              <div className="column-sections">
                                   <Basics />
                                 <Preferences />
                              </div>
                              <div className="column-sections">
                                   <Accounts onChanges={this.state.onChanges} saveIdentityDetails={this.saveIdentityDetails} />
                                   {this.props.hasIdentity && this.props.isnotFederatedUser && <Data onChanges={this.state.onChanges}/>}
                              </div>
                         </div>
                    </div>
                    {/* { !this.props.user?.firstName && (
                         <Loader showBackdrop />
                    )} */}
                    <Snackbar
                         anchorOrigin={{ horizontal: "center", vertical: "top" }}
                         open={(this.state.message !== "") ? true : false}
                         onClose={this.onMessageAutoHide}

                         message={this.state.message}
                         autoHideDuration={6000}
                         className="snackbar"

                    >
                         <MuiAlert elevation={6} variant="filled" severity={this.state.messageType}>{this.state.message}</MuiAlert>
                    </Snackbar>

               </form>
          )
     }

     onMessageAutoHide = () => {
          let isSaveSuccess = (this.state.messageType === "success");
          if (isSaveSuccess) {
               this.setState({ "message": "", messageType: this.state.messageType });
          }
     }
     /**
      * @function loadMyDetails
      * @description to get User details in ProfileX API
      */
     loadMyDetails = () => {

          ProfileService.request(
               {
                    url: UserProfileAPI.parseRequestURL(UserProfileAPI.get_my_details, {}),
                    method: 'GET'
               }
          ).then(
               (response) => {

                    this.props.dispatch(
                         {
                              type: actions.storeUserDetails,
                              payload: response.data
                         }
                    );
                    // this.loadMyPhoto(response.data.id);                    
               }
          ).catch(
               (error) => {

               }
          );

     }

     loadIdentityDetails = () => {
          IdentityService.request(
               {
                    url: IdentityAPI.parseRequestURL(IdentityAPI.get_my_details, {uuid: this.props.uuid}),
                    method: 'GET',
                    baseURL: this.props.endpoints.identityAPI
               }
          ).then(
               (response) => {
                    this.props.dispatch(
                         {
                              type: actions.storeIdentityDetails,
                              payload: response.data
                         }
                    );
               }
          ).catch(
               (error) => {

               }
          )
     }
     saveIdentityDetails = (post_data) => {
          this.setState({
               message: this.props.intl.formatMessage({
                    defaultMessage: "Updating..."
               }), messageType: "info"
          });
          IdentityService.request(
               {
                    url: IdentityAPI.parseRequestURL(IdentityAPI.get_my_details, {uuid: this.props.uuid}),
                    method: 'POST',
                    baseURL: this.props.endpoints.identityAPI,
                    data: post_data
               }
          ).then(
               (response) => {
                    if(response && response.status === 200){
                         this.setState({
                              message: this.props.intl.formatMessage({
                                   defaultMessage: "Updated successfully"
                              }), messageType: "success"
                         });
                         this.loadIdentityDetails();
                    }else{
                         this.setState({
                              message: this.props.intl.formatMessage({
                                   defaultMessage: "Error occured"
                              }), messageType: "error"
                         });
                    }
               }
          ).catch(
               (error) => {
                    this.setState({
                         message: this.props.intl.formatMessage({
                              defaultMessage: "Error occured"
                         }), messageType: "error"
                    });
               }
          )
     }
     reload = (isReloadCall) => {
          let user = this.props.user;
          this.props.dispatch(
               {
                    type: actions.clearUserDetails
               }
          );
          if (isReloadCall) {
               this.loadMyDetails();
               this.loadIdentityDetails();
               return;
          }
          setTimeout(() => {
               this.props.dispatch(
                    {
                         type: actions.storeUserDetails,
                         payload: user
                    }
               )
          }, 2);

     }

     verifyUpdates = () => {
          if (Object.keys(window.formHooks.errors).length !== 0) return false;

          let isChangeExists = false;
          window.user = cloneDeep(this.props.user);
          let formFields = ['firstName', 'lastName', 'country', 'language', 'timezone'];
          let formValues = window.formHooks.getValues();
          formFields.forEach(
               (field) => {
                    if (window.user[field] !== formValues[field]) {
                         isChangeExists = true;
                         window.user[field] = formValues[field];
                    }
               }
          );
          return isChangeExists;
     }

     escapeChar = (name_val) => {
          return name_val.replace(/&/g, "&amp;")
                .replace(/</g, "&lt;")
                .replace(/>/g, "&gt;")
                .replace(/"/g, "&quot;");   
       }

     saveUserDetails = () => {
          this.setState({
               message: this.props.intl.formatMessage({
                    defaultMessage: "Saving..."
               }), messageType: "info"
          });
          let apiCall = UserProfileAPI.update_user;
          this.verifyUpdates();

          let payload = ['firstName', 'lastName', 'country', 'language', 'timezone'].reduce((agg, val) => {
               agg[val] = (window.user[val] || "").length > 0 ? window.user[val] : undefined;
               return agg;
          }, {});

          payload['firstName'] = this.escapeChar(payload['firstName']);
          payload['lastName'] = this.escapeChar(payload['lastName']);


          ProfileService.put(
               UserProfileAPI.parseRequestURL(apiCall, { userId: window.user.uuid}),
               payload
          ).then(
               (response) => {
                    this.setState({
                         message: this.props.intl.formatMessage({
                              defaultMessage: "Changes Saved successfully..."
                         }), messageType: "success"
                    });

                    setTimeout(() => {
                         this.reload(true);
                    }, 100)
               }
          ).catch(
               (error) => {
                    this.setState({ message: error.response?.data?.message, messageType: "error" });
               }
          );
     }
}


/**
 * For mapping necessary state variables to current component properties
 * @param {Object} state - Immutable State Object
 * @return properties needed from state 
 */
const mapStateToProps = (state) => {
     const regionEndpoints = {
          'us': process.env.REACT_APP_US_IDENTITY_ENDPOINT,
          'eu': process.env.REACT_APP_EU_IDENTITY_ENDPOINT,
          'uk': process.env.REACT_APP_UK_IDENTITY_ENDPOINT,
          'eu-gb': process.env.REACT_APP_UK_IDENTITY_ENDPOINT,
          'ap-au': process.env.REACT_APP_AU_IDENTITY_ENDPOINT
     };

     const federation_origin = (state.auth?.profile?.federation_origin) ?? null;

     return {
          countries: state.common.countries,        
          accessToken: state.auth.access_token,
          user: state.userManager,
          uuid: state.auth?.profile?.sub,
          endpoints: {
               identityAPI: regionEndpoints[state.auth?.profile?.data_region ?? "us"]
          },
          isnotFederatedUser: federation_origin || state.userManager?.identity?.has_federations ? false : true,
          federation_origin,
          hasIdentity: (state.userManager?.identity) ?? null
     }
};

export default withRouter(connect(mapStateToProps)(injectIntl(UserForm)));