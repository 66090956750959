export const flags = {
     show_passkey : true,
     show_passkey_as_mfa: true, //for all types of users
     show_reset_totp: true,
     show_sms_mfa: false
}

export const configs = {
     totp_reset_end_date : "Jan 31, 2025",
     sms_mfa_end_date: "Mar 01, 2025"
}