// React
import React from "react";

/// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {Dialog, DialogContent, useTheme, Slide, Link} from "@material-ui/core";
import './_data.scss';
import { FormattedMessage } from "react-intl";
import { useEffect } from "react";
import { useState, useCallback } from "react";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import * as actions from '../../../store/actions/actions';

const Transition = React.forwardRef(function Transition(props, ref) {
     return <Slide direction="left" ref={ref} {...props} />;
});


const DataDialog = (props) => {
     const [loaded, setLoaded] = useState(false);
     const theme = useTheme();
     const loadHandler = useCallback(() => setLoaded(true), []);
     const closeHandler = useCallback(() => props.onClose(null), [props]);
     const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

     useEffect(() => {
          let listener = null;

          if (loaded && !listener) {
               const childWindow = document.getElementById('identity-data-frame').contentWindow;

               listener = message => {
                    if (message.source !== childWindow) {
                         return; // Skip message in this event listener
                    }

                    if (message.data.status === "complete") {
                         if (props.onClose) {
                              props.onClose(message.data)
                         }
                    }
               };

               window.addEventListener('message', listener, false);
          }

          return () => {
               if (listener) {
                    window.removeEventListener('message', listener, false);
               }
          };
     }, [props, loaded]);

     return (
          <Dialog
               open={props.open}
               TransitionComponent={Transition}
               keepMounted
               onClose={closeHandler}
               aria-labelledby="alert-dialog-slide-title"
               aria-describedby="alert-dialog-slide-description"
               fullWidth={true}
               className="accountFrameDialog"
               fullScreen={fullScreen}
               disableBackdropClick={true}
               disableEscapeKeyDown={true}
          >
               <DialogContent>
                    {/* <span className="popup-cancel-button" onClick={this.closeDataFrame}>Cancel</span> */}
                    <iframe id="identity-data-frame" onLoad={loadHandler} src={props.frameUrl} title="Data Link" allow= "publickey-credentials-get *"/>
               </DialogContent>
          </Dialog>
     )
}


const DataRegionInfo = (props) => {
     return (<>
          {
               (<div>
                    <div className="info-holder">
                         <FormattedMessage defaultMessage="Your data region is where your Trimble Profile details are stored."/>
                    </div>

                    <div className="region-holder">
                         <Typography variant="subtitle2" component="label">
                              <FormattedMessage defaultMessage="Data Region" />                              
                         </Typography>
                         
                         <Typography variant="subtitle1">
                              {props.region === "eu" ? <FormattedMessage defaultMessage="European Union (EU)" /> 
                              : (props.region === "uk" || props.region === "eu-gb") ? <FormattedMessage defaultMessage="United Kingdom (UK)" />
                              : props.region === "ap-au" ? <FormattedMessage defaultMessage="Australia (AU)" /> 
                              : <FormattedMessage defaultMessage="United States (US)" />}
                         </Typography>
                    </div>
                    
                    <div className="link-holder">
                         <div className="link" onClick={() => { props.openDataFrame(props.endpoints.changeDataRegion) }}>
                         <FormattedMessage defaultMessage="Change data region" /></div>
                    </div>

                    <div className="note-content">
                         <FormattedMessage defaultMessage="Note: Your Trimble Profile details are stored separately from product data. Changing your data region will not affect active product subscriptions or existing stored product data."/>
                    </div>

                    <div className="note-learn-more">
                         <FormattedMessage 
                              defaultMessage="{url} about how Trimble handles your data." 
                              values={
                                   {
                                        url: <Link className="inherit-text pt-1" target="_blank"
                                        rel="noopener noreferrer" href="https://www.trimble.com/en/our-commitment/responsible-business/data-privacy-and-security/data-privacy-center/privacy-notice"> <FormattedMessage defaultMessage="Learn more" /></Link>
                                   } 
                              } 
                         />
                    </div>

               </div>
               )  
          }
     </>);
}


/**
 * @class Basics
 * @description component contains Account Details
 */
class Data extends React.PureComponent {

     /**
      * @function constructor
      * @param {*} props - Having properties passed by parent element
      *  * used for state initialization
      */
     constructor(props) {
          super(props);

          this.state = {
               frameUrl: null
          };

          this.bindedopenDataFrame = this.openDataFrame.bind(this)
     }

     componentDidUpdate(_prevProps, prevState) {
          //     /
     }

     openDataFrame = (frameUrl) => {
          const params = {
               username: this.props.preferredUsername || this.props.emails,
               application: this.props.applicationId,
               ui_locales: this.props.locale,
               federation_origin: this.props.federation_origin,
               origin: window.location.origin
          }

          const queryComponents = Object.keys(params).reduce((agg, val) => {
               if (typeof params[val] === "string" && params[val]?.length > 0) {
                    agg.push(`${val}=${encodeURIComponent(params[val])}`)
               }

               return agg
          }, []);

          this.setState({
               frameUrl: queryComponents.length > 0 ? `${frameUrl}?${queryComponents.join("&")}` : frameUrl
          });
     }

     closeDataFrame = (data) => {
          if (this.props.onChanges) {
               if (data?.multifactor_changed || data?.change_email || data?.change_recovery_email || data?.remove_recovery_email || data?.passkey_changed) {
                    this.props.onChanges();
               } else if (data?.password_changed || data?.account_deleted || data?.change_data_region || data?.policy_changed) {
                    this.props.dispatch({ type: actions.signOut });
               }
          }

          this.setState({ frameUrl: null });
     }

     /**
      * @function render
      * @description repaints the component on each change of state and props value
      */
     render() {
          return (
               <div className="card data">
                    <h3>
                         <FormattedMessage defaultMessage="Data & Privacy" />
                    </h3>
                    <div className="form-holder">
                         <>
                              <div>
                                   <h4><FormattedMessage defaultMessage="Data Region" /></h4>
                              </div>
                              <DataRegionInfo region={this.props.region} endpoints={this.props.endpoints} openDataFrame={this.bindedopenDataFrame}  />
                         </>
                         <DataDialog frameUrl={this.state.frameUrl} open={(this.state.frameUrl !== null) ? true : false} onClose={this.closeDataFrame} />
                    </div>
               </div >
          );
     }
}

/**
 * For mapping necessary state variables to current component properties
 * @param {Object} state - Immutable State Object
 * @return properties needed from state 
 */
const mapStateToProps = (state) => {
     const regionEndpoints = {
          'us': process.env.REACT_APP_US_IDENTITY_ENDPOINT,
          'eu': process.env.REACT_APP_EU_IDENTITY_ENDPOINT,
          'uk': process.env.REACT_APP_UK_IDENTITY_ENDPOINT,
          'eu-gb': process.env.REACT_APP_UK_IDENTITY_ENDPOINT,
          'ap-au': process.env.REACT_APP_AU_IDENTITY_ENDPOINT
     };

     const userRegion = regionEndpoints[state.auth?.profile?.data_region ?? "us"]
     const federation_origin = (state.auth?.profile?.federation_origin) ?? null;

     return {
          emails: (state.userManager?.identity?.email) ?? null,
          preferredUsername: (state.userManager?.identity?.preferred_username) ?? null,
          applicationId: (state.auth?.profile?.azp) ?? null,
          endpoints: {
               changeDataRegion: `${userRegion}/ui/data_region_change.html`,
          },
          region: state.auth?.profile?.data_region,
          locale: (state.userManager?.language) ?? null,
          federation_origin
     }
};
export default withRouter(connect(mapStateToProps)(Data));
